import React from 'react';
import { useContext, useEffect, useState } from 'react';
import WizardContext from '../../../../store/wizard-context.js';
import DataContext from '../../../../store/data-context.js';
import InitDataContext from '../../../../store/init-data-context.js';
import ProductSystemItemContext from '../../../../store/product-system-item-context.js';
import ProductSystemContext from '../../../../store/product-system-context.js';
import './WizardStepProductSystems.css';
import Footer from '../../../Footer.js';
import ProductSystemCard from '../../../ProductSystemCard.js'
import ProductSystemItemRohr from '../../../ProductSystemItemRohr.js'
import ProductSystemItemKabel from '../../../ProductSystemItemKabel.js'
import { URLS } from '../../../../urls.js';
import * as utils from '../../../../utils.js';

const WizardStepProductSystems = () => {
    const wizardCtx = useContext(WizardContext);
    const dataCtx = useContext(DataContext);
    const initDataCtx = useContext(InitDataContext);
    const productSystemCtx = useContext(ProductSystemContext);
    const productSystemItemCtx = useContext(ProductSystemItemContext);
    const [anzTreffer, setAnzTreffer] = useState([]);

    const [selectedProductSystemId, setSelectedProductSystemId] = useState(undefined);
    const [selectedProductSystemItem, setSelectedProductSystemItem] = useState(undefined);

    useEffect(() => {
        setSelectedProductSystemId(undefined);
        }, [wizardCtx.currentStep]);

    useEffect(() => {
        if (wizardCtx.currentStep === 'productsystems') {
            if (productSystemItemCtx.productSystemItems !== undefined) {
            setAnzTreffer(productSystemItemCtx.productSystemItems.length);
            } 
        }
        }, [productSystemItemCtx.productSystemItems, wizardCtx.currentStep]);


    if (wizardCtx.currentStep !== 'productsystems'){
        return null;
      }

    const goToReadjustment = () => {
        //productSystemItemCtx.clear();
        return wizardCtx.goToReadjustment;
    }

    const goToOverviewSolution = () => wizardCtx.goToOverviewSolution;


    const selectProductSystem = (productSystem) => {
        setSelectedProductSystemId(productSystem.id);
        setSelectedProductSystemItem(undefined);
        productSystemCtx.set_product_system(productSystem);
        return fetchProductSystemItems(productSystem.id);
    }

    const selectProductSystemItem = (productSystemItem) => {
        setSelectedProductSystemItem(productSystemItem);
        productSystemItemCtx.set_product_system_item(productSystemItem);
    }


    const fetchProductSystemItems = (productSystemId) => {
          const params = new URLSearchParams({ 
            item_35_tec_assesment: dataCtx.custom_item_35_tec_assesment.current,
            item_02_type: dataCtx.custom_item_02_type.current,
            item_14_fire_rating: dataCtx.custom_item_14_fire_rating.current,
            item_pipe_end_configuration: dataCtx.item_pipe_end_configuration.current,
            max_dn_single_cable: dataCtx.max_dn_single_cable.current,
            max_dn_cable_bundle: dataCtx.max_dn_cable_bundle.current,
            max_dn_electro_inst_pipes: dataCtx.max_dn_electro_inst_pipes.current,
            item_custom_penetrating_service_type: dataCtx.item_custom_penetrating_service_type.current,
            item_24_diameter: dataCtx.item_24_diameter.current,
            item_24_outer_diameter: dataCtx.item_24_outer_diameter.current,
            custom_item_07_length: dataCtx.custom_item_07_length.current,
            item_custom_material: dataCtx.item_custom_material.current,
            item_custom_given_insulation_material: dataCtx.item_custom_given_insulation_material.current,
            item_25_wall_thickness: dataCtx.item_25_wall_thickness.current,
            item_26_penetration_angle: dataCtx.item_26_penetration_angle.current,
            item_custom_given_insulation_configuration: dataCtx.item_custom_given_insulation_configuration.current,
            item_custom_no_penetrating_services: dataCtx.item_custom_no_penetrating_services.current,
            item_34_given_insulation_thickness: dataCtx.item_34_given_insulation_thickness.current,
            mep_penetration_id: dataCtx.item_custom_mep_penetration_id.current,
            mep_material_id: dataCtx.item_custom_mep_material_id.current,
            product_system_id: productSystemId,
            insulation_product_id: dataCtx.item_custom_insulation_product_id.current
            
        });

            fetch(URLS['BACKEND'] + `/api/revit/fetch_product_system_items?${params}`)
            .then(response => response.json())
            .then(data => productSystemItemCtx.set_product_system_items(data))
            .catch(
                error => {
                    setAnzTreffer(0);
                }
            );
        }

    const tableHeader = (type) => {
        return (type === "rohr") ?
        <thead>
            <tr>
                <th className="penetraton">Penetration services</th>
                <th className="diameter">Diameter [mm]</th>
                <th className="thickness">Wall thickness [mm]</th>
                <th className="insulation">Insulation</th>
                <th className="insulation-thickness">Insulation<br />thickness [mm]</th>
                <th className="insulation-length">Insulation<br />length [mm]</th>
                <th className='products'>Product</th>
                <th className="frc">FRC</th>
                <th className="certification">Reference</th>
            </tr>
        </thead> :
        <thead>
            <tr>
                <th colspan="3">Penetration services</th>
                <th colspan="3" style={{textAlign: "center"}}>Max. diameter [mm]</th>
                <th className="products">Product</th>
                <th className="frc">FRC</th>
                <th className="certification">Reference</th>
            </tr>
            <tr>
                <th className="penetration" colspan="3"></th>
                <th className="diameter-bundle">bundle</th>
                <th className="diameter-elec-cond">elec. cond. /<br />Microtube</th>
                <th className="diameter-single-cable">single cable</th>
                <th className="products"></th>
                <th className="frc"></th>
                <th className="certification"></th>
            </tr>
        </thead>
    }

    const hasItems = (items) => {
        return (items !== undefined && items.length > 0);
    }

    const currentProductSystemId = () => {
        return (productSystemCtx.productSystem !== undefined)? productSystemCtx.productSystem.id : undefined;
    }   

    const tableClass = (productSystemItems) => {
        return (hasItems(productSystemItems) && productSystemItems[0])? "product-items " + productSystemItems[0].line_type : "product-items";
    }

    const buttonGoToOverviewSolutionEnabled = (selectedProductSystemItem) => {
        return (selectedProductSystemItem !== undefined);
    }

    const productSystemImageUrl = (productSystem, element_material, orientation) => {
        const installation_type = (orientation === "wall") ? "wand" : "decke";
        const image = productSystem.product_system_images.find( (image) => { 
            return image.installation_element_material === element_material && image.installation_element_type === installation_type
        } );
        if (image !== undefined) {
            return image.image_url;
        }   
    }

    const showDetails = (item) => {
        return (selectedProductSystemItem !== undefined && item.id === selectedProductSystemItem.id);
    }

    // übergangsweise 
    // Wenn der Wert insulation_configuration = "CS" ist, dann sollen die Produkte mit CS zuerst angezeigt werden 
    // und dann die anderen Produkte
    // Das wird mittels hit_accurancy erreicht. Die Zeilen mit hit_accuracy < 100 sollen auch etwas dezenter dargestellt werden.

    const productSystemItems = () => {
        // if (dataCtx.item_custom_given_insulation_configuration.current === "cs") {
        //     let show_first = productSystemItemCtx.productSystemItems.filter( (item) => { return item.insulation_configuration === "CS" } );
        //     show_first.forEach (item => { item.hit_accurancy = 100 }); 
        //     let show_last = productSystemItemCtx.productSystemItems.filter( (item) => { return item.insulation_configuration !== "CS" } );
        //     show_last.forEach (item => { item.hit_accurancy = 50 }); 
        //     return [...show_first, ...show_last]
        // } else {
        //     return productSystemItemCtx.productSystemItems
        // }


        return productSystemItemCtx.productSystemItems.sort( (a, b) => { return b.hit_accurancy - a.hit_accurancy } );
    }

    return (
    <span>
        <header>
            Suitable Hensel product systems
        </header>
        <hr />
        <div className="sub-title">Matching data set</div>
        <p>Technical Assesment: { utils.toText(dataCtx.custom_item_35_tec_assesment.current) } | 
        Construction element: { utils.toText(dataCtx.custom_item_02_type.current)} | 
        Material: { utils.toText(dataCtx.item_custom_material.current) } | 
        Thickness: { utils.toText("thickness_" + dataCtx.custom_item_07_length.current) } | 
        Fire-Rating: { dataCtx.custom_item_14_fire_rating.current } min | 
        { (dataCtx.item_custom_shape.current === "round") ? " PfV shape: round | " : null } 
        { (dataCtx.item_custom_shape.current === "rectangle") ? " PfV shape: rectangle | " : null }
        { (dataCtx.item_custom_shape.current === "round") ? " MEP diameter: " + dataCtx.item_24_diameter.current + " mm | " : null }
        { (dataCtx.item_custom_shape.current === "rectangle") ? " MEP Width: " + initDataCtx.item_mep_elements_size_height + " x Length: " + initDataCtx.item_mep_elements_size_height + " mm | " : null }
        Penetration angle: { dataCtx.item_26_penetration_angle.current } &deg;</p>
        <div className="sub-title">Suitable Hensel product systems with tested services that match all above criteria</div>
        <p>Please select the desired matching product system.</p>
        <div className="card-container">
            { productSystemCtx.productSystems.map( 
                ps => <ProductSystemCard 
                  key={"product-system-" + ps.id} 
                  title={ps.title}
                  imageUrl={productSystemImageUrl(ps, dataCtx.item_custom_material.current, dataCtx.custom_item_02_type.current)} 
                  isSelected={ ps.id ===  currentProductSystemId() }
                  onClick={ () => selectProductSystem(ps)  } />)}
        </div>
        <hr />
        { (selectedProductSystemId !== undefined) ?     <span><h4>
                { "Penetrating service types found: " + anzTreffer }
        </h4> <hr /></span> : null
        }

       { anzTreffer > 0 ? <p>Please select a matching penetrating service from the list.</p> : null }

        

         
        <div className="item-container">
            <table className={ tableClass(productSystemItems()) } >
            
            { (hasItems(productSystemItems()) === true) ? tableHeader(productSystemItems()[0].line_type) : null }
              <tbody>

            { ( hasItems(productSystemItems()) && productSystemItems()[0].line_type === "rohr" ) ? 
              productSystemItems().map( (item) => { return <ProductSystemItemRohr 
                key={"product-system-item-" + item.id } 
                item={item}
                showDetails={ () => showDetails(item) }
                accurancy={item.hit_accurancy} 
                isSelected={ selectedProductSystemItem !== undefined && item.id === selectedProductSystemItem.id } 
                onClick={ () => selectProductSystemItem(item)} /> } ) 
              : null 
            }

            

            { ( hasItems(productSystemItems()) && productSystemItems()[0].line_type === "kabel" ) ?
              productSystemItems().map( (item) => { return <ProductSystemItemKabel 
                key={"product-system-item-" + item.id } 
                item={item} 
                showDetails={ () => showDetails(item) }
                isSelected={ selectedProductSystemItem !== undefined && item.id === selectedProductSystemItem.id } 
                onClick={ () => selectProductSystemItem(item)} /> }  )
              : null 
            }   

              </tbody>
            </table>
        </div>

        <Footer></Footer>
        
        <div className="navbar">
            <button className="back-btn" onClick={goToReadjustment()} style={{textDecoration: "none", color: "black"}} >{"<< Back to matching of data"}</button>
            { (buttonGoToOverviewSolutionEnabled(selectedProductSystemItem)) ? 
              <button className="continue-btn" onClick={ goToOverviewSolution() } style={{textDecoration: "none", color: "black"}}>{"Continue to transfer to Autodesk Revit >>"}</button> 
              : null
            }

      
        </div>
        
    </span>
    );
}

export default WizardStepProductSystems;