import React from 'react';
import { URLS } from '../urls.js';

const ProductSystemCard = (params) => {

    const title = params.title || "";
    const imageUrl = URLS['BACKEND'] + params.imageUrl || "";
    const onClick = params.onClick || (() => {});
    const isSelected = params.isSelected;
    const highlightStatus = (isSelected) ? "card-border highlight" : "card-border";

    const formattedTitle = (title) => {
      const parts = title.split('®');
      if (parts.length !== 2) return title;
      
      const secondPart = parts[1].trim();
      const smallStyle = secondPart.length > 28 ? ' style="font-size: 0.75em;"' : '';
      
      return `${parts[0]}®<br/><span${smallStyle}>${secondPart}</span>`;
    }
    return (
      <div className={ highlightStatus }>
        <div className="card" onClick={ () => onClick() }>
          <img src={imageUrl} alt={title} />
          <div className="card-body">
            <div className="card-title" dangerouslySetInnerHTML={{ __html: formattedTitle(title) }}></div>
        </div>
      </div>
    </div>
    )
}


export default ProductSystemCard;