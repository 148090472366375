
import { useContext } from 'react';
import React, { useState } from 'react';
import WizardContext from '../../../../store/wizard-context.js';
import InitDataContext from '../../../../store/init-data-context.js';
import './WizardStepEditStart.css';
import Footer from '../../../Footer.js';

const WizardStepEditStart = () => {
    const wizardCtx = useContext(WizardContext);
    const initDataCtx = useContext(InitDataContext);

    const set_data_value = async (name, value) => {
        return initDataCtx.set_data_value(name, value);
    }

    const goToIntro = () => wizardCtx.goToIntro


    const [formData, setFormData] = useState({});


    const importStructure = json => {
        console.log(json);
        return {
            "UniqueId": json["UniqueId"],
            "Type": json["Type"],
            "Direction": {
                "X": json?.["Direction"]?.["X"],
                "Y": json?.["Direction"]?.["Y"],
                "Z": json?.["Direction"]?.["Z"],
            },
            "LocationMm": {
                "X": json?.["LocationMm"]?.["X"],
                "Y": json?.["LocationMm"]?.["Y"],
                "Z": json?.["LocationMm"]?.["Z"],
            },
            "Size": {
                "LengthMm": json?.["Size"]?.["LengthMm"],
                "HeightMm": json?.["Size"]?.["HeightMm"],
                "WidthMm": json?.["Size"]?.["WidthMm"],
                "DiameterMm": json?.["Size"]?.["DiameterMm"],
            },
            "StructuralElements": [{ 
                "UniqueId": json?.["StructuralElements"][0]["UniqueId"],
                "Name": json?.["StructuralElements"][0]?.["Name"],
                "MaterialName": json?.["StructuralElements"][0]?.["MaterialName"],
                "MaterialCategory": json?.["StructuralElements"][0]?.["MaterialCategory"],
                "MaterialClass": json?.["StructuralElements"][0]?.["MaterialClass"],
                "FireRating": json?.["StructuralElements"][0]?.["FireRating"],
                "StructuralAsset": {
                    "DensityKgM3": json?.["StructuralElements"][0]?.["StructuralAsset"]?.["DensityKgM3"],
                },
            }],
            "MepElements": [{
                "UniqueId": json?.["MepElements"][0]?.["UniqueId"],
                "Category": json?.["MepElements"][0]?.["Category"],
                "Description": json?.["MepElements"][0]?.["Description"],
                "MaterialCode": json?.["MepElements"][0]?.["MaterialCode"],
                "Direction": {
                    "X": json?.["MepElements"][0]?.["Direction"]?.["X"],
                    "Y": json?.["MepElements"][0]?.["Direction"]?.["Y"],
                    "Z": json?.["MepElements"][0]?.["Direction"]?.["Z"],
                },
                "LocationMm": {
                    "X": json?.["MepElements"][0]?.["LocationMm"]?.["X"],
                    "Y": json?.["MepElements"][0]?.["LocationMm"]?.["Y"],
                    "Z": json?.["MepElements"][0]?.["LocationMm"]?.["Z"],
                },
                "Size": {
                    "DiameterMm": json?.["MepElements"][0]?.["Size"]?.["DiameterMm"],
                    "OuterDiameterMm": json?.["MepElements"][0]?.["Size"]?.["OuterDiameterMm"],
                    "HeightMm": json?.["MepElements"][0]?.["Size"]?.["HeightMm"],
                    "WidthMm": json?.["MepElements"][0]?.["Size"]?.["WidthMm"],   
                    "WallThicknessMm": json?.["MepElements"][0]?.["Size"]?.["WallThicknessMm"],
                },
                "AngleDeg": json?.["MepElements"][0]?.["AngleDeg"],   
                "Insulation": {
                    "UniqueId": json?.["MepElements"][0]?.["Insulation"]?.["UniqueId"],   
                    "Code": json?.["MepElements"][0]?.["Insulation"]?.["Code"],
                    "Description": json?.["MepElements"][0]?.["Insulation"]?.["Description"],
                    "NationalCode": json?.["MepElements"][0]?.["Insulation"]?.["NationalCode"],
                    "MaterialCode": json?.["MepElements"][0]?.["Insulation"]?.["MaterialCode"],
                    "CoatingMaterialCode": json?.["MepElements"][0]?.["Insulation"]?.["CoatingMaterialCode"],
                    "ThicknessMm": json?.["MepElements"][0]?.["Insulation"]?.["ThicknessMm"],
                }
            }]
        }
   }

   const copyJsonToForm = json => {
        setFormData({
            item_01_pfv_uniqueID: json["UniqueId"],
            item_02_type: json["Type"],
            item_04_diameter: json?.["Size"]?.["DiameterMm"],
            item_05_height: json?.["Size"]?.["HeightMm"],
            item_06_width: json?.["Size"]?.["WidthMm"],
            item_07_length: json?.["Size"]?.["LengthMm"],
            item_09_construction_element_uid: json?.["StructuralElements"][0]?.["UniqueId"],
            item_10_name: json?.["StructuralElements"][0]?.["Name"],
            item_11_material_name: json?.["StructuralElements"][0]?.["MaterialName"],
            item_12_material_category: json?.["StructuralElements"][0]?.["MaterialCategory"],
            item_13_material_class: json?.["StructuralElements"][0]?.["MaterialClass"],
            item_14_fire_rating: json?.["StructuralElements"][0]?.["FireRating"],
            item_15_structural_asset: json?.["StructuralElements"][0]?.["StructuralAsset"]?.["DensityKgM3"],
            item_17_MEP_element_uniqueID: json?.["MepElements"][0]?.["UniqueId"],
            item_18_category: json?.["MepElements"][0]?.["Category"],
            item_19_description: json?.["MepElements"][0]?.["Description"],
            item_20_material_code: json?.["MepElements"][0]?.["MaterialCode"],
            item_mep_elements_size_height: json?.["MepElements"][0]?.["Size"]?.["HeightMm"],
            item_23_width: json?.["MepElements"][0]?.["Size"]?.["WidthMm"],
            item_24_diameter: json?.["MepElements"][0]?.["Size"]?.["DiameterMm"],
            item_24_outer_diameter: json?.["MepElements"][0]?.["Size"]?.["OuterDiameterMm"],
            item_25_wall_thickness: json?.["MepElements"][0]?.["Size"]?.["WallThicknessMm"],
            item_26_penetration_angle: json?.["MepElements"][0]?.["AngleDeg"],
            item_28_insulation_uid: json?.["MepElements"][0]?.["Insulation"]?.["UniqueId"],
            item_30_description: json?.["MepElements"][0]?.["Insulation"]?.["Description"],
            item_31_national_code: json?.["MepElements"][0]?.["Insulation"]?.["NationalCode"],
            item_32_material_code: json?.["MepElements"][0]?.["Insulation"]?.["MaterialCode"],
            item_33_coating_material_code: json?.["MepElements"][0]?.["Insulation"]?.["CoatingMaterialCode"],
            item_34_given_insulation_thickness: json?.["MepElements"][0]?.["Insulation"]?.["ThicknessMm"],
            item_direction_x: json?.["Direction"]?.["X"],
            item_direction_y: json?.["Direction"]?.["Y"],
            item_direction_z: json?.["Direction"]?.["Z"],
            item_location_x: json?.["LocationMm"]?.["X"],
            item_location_y: json?.["LocationMm"]?.["Y"],
            item_location_z: json?.["LocationMm"]?.["Z"],
            item_mep_direction_x: json?.["MepElements"][0]?.["Direction"]?.["X"],
            item_mep_direction_y: json?.["MepElements"][0]?.["Direction"]?.["Y"],
            item_mep_direction_z: json?.["MepElements"][0]?.["Direction"]?.["Z"],
            item_mep_location_x: json?.["MepElements"][0]?.["LocationMm"]?.["X"],
            item_mep_location_y: json?.["MepElements"][0]?.["LocationMm"]?.["Y"],
            item_mep_location_z: json?.["MepElements"][0]?.["LocationMm"]?.["Z"]
        });
    }


    const handleJsonImport = () => {
      
        const json = formData.json_data;

        // test if json is valid
        try {
            JSON.parse(json);
        } catch (e) {
            alert("Invalid JSON data");
            return;
        }

        const jsonObject = importStructure(JSON.parse(json));
        copyJsonToForm(jsonObject)

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

      const copyToClipboard = text => {
        const tempInput = document.createElement('textarea');
        tempInput.style.position = 'absolute';
        tempInput.style.left = '-9999px';
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
    };

    
    const showJSON = () => {
     const jData = {

            "UniqueId": formData.item_01_pfv_uniqueID,
            "Type": formData.item_02_type,
            "Direction": {
                "X": formData.item_direction_x,
                "Y": formData.item_direction_y,
                "Z": formData.item_direction_z,
            },
            "LocationMm": {
                "X": formData.item_location_x,
                "Y": formData.item_location_y,
                "Z": formData.item_location_z,
            },
            "Size": {
                "LengthMm": formData.item_07_length,
                "HeightMm": formData.item_05_height,
                "WidthMm": formData.item_06_width,
                "DiameterMm": formData.item_04_diameter,
            },
            "StructuralElements": [{
                "UniqueId": formData.item_09_construction_element_uid,
                "Name": formData.item_10_name,
                "MaterialName": formData.item_11_material_name,
                "MaterialCategory": formData.item_12_material_category,
                "MaterialClass": formData.item_13_material_class,
                "FireRating": formData.item_14_fire_rating,
                "StructuralAsset": {
                    "DensityKgM": formData.item_15_structural_asset,
                },
            }],
            "MepElements": [{
                "UniqueId": formData.item_17_MEP_element_uniqueID,
                "Category": formData.item_18_category,
                "Description": formData.item_19_description,
                "MaterialCode": formData.item_20_material_code,
                "Direction": {
                    "X": formData.item_mep_direction_x,
                    "Y": formData.item_mep_direction_y,
                    "Z": formData.item_mep_direction_z,
                },
                "LocationMm": {
                    "X": formData.item_mep_location_x,
                    "Y": formData.item_mep_location_y,
                    "Z": formData.item_mep_location_z,
                },
                "Size": {
                    "DiameterMm": formData.item_24_diameter,
                    "OuterDiameterMm": formData.item_24_outer_diameter,
                    "HeightMm": formData.item_mep_elements_size_height,
                    "WidthMm": formData.item_23_width,
                    "WallThicknessMm": formData.item_25_wall_thickness,
                },
                "AngleDeg":  formData.item_26_penetration_angle,
                "Insulation": {
                    "UniqueId": formData.item_28_insulation_uid,
                    "Code": formData.item_28_insulation_uid,
                    "Description": formData.item_30_description,
                    "NationalCode": formData.item_31_national_code,
                    "MaterialCode": formData.item_32_material_code,
                    "CoatingMaterialCode": formData.item_33_coating_material_code,
                    "ThicknessMm": formData.item_34_given_insulation_thickness,
                }
            }]
        }

          copyToClipboard(JSON.stringify(jData));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        set_data_value('item_01_pfv_uniqueID', formData['item_01_pfv_uniqueID']);
        set_data_value('item_02_type', formData['item_02_type']);
        set_data_value('item_04_diameter', formData['item_04_diameter']);
        set_data_value('item_05_height', formData['item_05_height']);
        set_data_value('item_06_width', formData['item_06_width']);
        set_data_value('item_07_length', formData['item_07_length']);
        set_data_value('item_09_construction_element_uid', formData['item_09_construction_element_uid']);
        set_data_value('item_10_name', initDataCtx.item_10_name);
        set_data_value('item_11_material_name', initDataCtx.item_11_material_name);
        set_data_value('item_12_material_category', initDataCtx.item_12_material_category);
        set_data_value('item_13_material_class', initDataCtx.item_13_material_class);
        set_data_value('item_14_fire_rating', formData['item_14_fire_rating']);
        set_data_value('item_15_structural_asset', formData['item_15_structural_asset']);
        set_data_value('item_17_MEP_element_uniqueID', formData['item_17_MEP_element_uniqueID']);
        set_data_value('item_18_category', formData['item_18_category']);
        set_data_value('item_19_description', formData['item_19_description']);
        set_data_value('item_20_material_code', formData['item_20_material_code']);
        set_data_value('item_mep_elements_size_height', formData['item_mep_elements_size_height']);
        set_data_value('item_23_width', formData['item_23_width']);
        set_data_value('item_24_diameter', formData['item_24_diameter']);
        set_data_value('item_24_outer_diameter', formData['item_24_outer_diameter']);
        set_data_value('item_25_wall_thickness', formData['item_25_wall_thickness']);
        set_data_value('item_26_penetration_angle', formData['item_26_penetration_angle']);
        set_data_value('item_28_insulation_uid', formData['item_28_insulation_uid']);
        set_data_value('item_30_description', formData['item_30_description']);
        set_data_value('item_31_national_code', formData['item_31_national_code']);
        set_data_value('item_32_material_code', formData['item_32_material_code']);
        set_data_value('item_33_coating_material_code', formData['item_33_coating_material_code']);
        set_data_value('item_34_given_insulation_thickness', formData['item_34_given_insulation_thickness']);
        set_data_value('item_35_tec_assesment', formData['item_35_tec_assesment']);
        set_data_value('item_direction_x', formData['item_direction_x']);
        set_data_value('item_direction_y', formData['item_direction_y']);
        set_data_value('item_direction_z', formData['item_direction_z']);
        set_data_value('item_location_x', formData['item_location_x']);
        set_data_value('item_location_y', formData['item_location_y']);
        set_data_value('item_location_z', formData['item_location_z']);
        set_data_value('item_mep_direction_x', formData['item_mep_direction_x']);
        set_data_value('item_mep_direction_y', formData['item_mep_direction_y']);
        set_data_value('item_mep_direction_z', formData['item_mep_direction_z']);
        set_data_value('item_mep_location_x', formData['item_mep_location_x']);
        set_data_value('item_mep_location_y', formData['item_mep_location_y']);
        set_data_value('item_mep_location_z', formData['item_mep_location_z']);
        
        goToIntro()();
    };


    const areAllAttributesEmpty = obj => {
        for (let key in obj) {
            if (obj[key] !== null && obj[key] !== "") {
                return false;
            }
        }
        return true;
    };

    if (wizardCtx.currentStep !== 'editstart'){
        return null;
    } else {
        if (areAllAttributesEmpty(formData)) {
        setFormData({
            item_01_pfv_uniqueID: initDataCtx.item_01_pfv_uniqueID,
            item_02_type: initDataCtx.item_02_type,
            item_04_diameter: initDataCtx.item_04_diameter,
            item_05_height: initDataCtx.item_05_height,
            item_06_width: initDataCtx.item_06_width,
            item_07_length: initDataCtx.item_07_length,
            item_09_construction_element_uid: initDataCtx.item_09_construction_element_uid,
            item_10_name: initDataCtx.item_10_name,
            item_11_material_name: initDataCtx.item_11_material_name,
            item_12_material_category: initDataCtx.item_12_material_category,
            item_13_material_class: initDataCtx.item_13_material_class,
            item_14_fire_rating: initDataCtx.item_14_fire_rating,
            item_15_structural_asset: initDataCtx.item_15_structural_asset,
            item_17_MEP_element_uniqueID: initDataCtx.item_17_MEP_element_uniqueID,
            item_18_category: initDataCtx.item_18_category,
            item_19_description: initDataCtx.item_19_description,
            item_20_material_code: initDataCtx.item_20_material_code,
            item_mep_elements_size_height: initDataCtx.item_mep_elements_size_height,
            item_23_width: initDataCtx.item_23_width,
            item_24_diameter: initDataCtx.item_24_diameter,
            item_24_outer_diameter: initDataCtx.item_24_outer_diameter,
            item_25_wall_thickness: initDataCtx.item_25_wall_thickness,
            item_26_penetration_angle: initDataCtx.item_26_penetration_angle,
            item_28_insulation_uid: initDataCtx.item_28_insulation_uid,
            item_30_description: initDataCtx.item_30_description,
            item_31_national_code: initDataCtx.item_31_national_code,
            item_32_material_code: initDataCtx.item_32_material_code,
            item_33_coating_material_code: initDataCtx.item_33_coating_material_code,
            item_34_given_insulation_thickness: initDataCtx.item_34_given_insulation_thickness,
            item_35_tec_assesment: initDataCtx.item_35_tec_assesment,
            item_direction_x: initDataCtx.item_direction_x,
            item_direction_y: initDataCtx.item_direction_y,
            item_direction_z: initDataCtx.item_direction_z,
            item_location_x: initDataCtx.item_location_x,
            item_location_y: initDataCtx.item_location_y,
            item_location_z: initDataCtx.item_location_z,
            item_mep_direction_x: initDataCtx.item_mep_direction_x,
            item_mep_direction_y: initDataCtx.item_mep_direction_y,
            item_mep_direction_z: initDataCtx.item_mep_direction_z,
            item_mep_location_x: initDataCtx.item_mep_location_x,
            item_mep_location_y: initDataCtx.item_mep_location_y,
            item_mep_location_z: initDataCtx.item_mep_location_z

          } );
        }
    }

    

    return (
        <span>
        <header>
            Edit Start Conditions (Simulate MagiCAD/Revit-Data)
        </header>
        <hr />

        <div className="container">
            <div className="table-title">General Requirements</div>
            <form onSubmit={handleSubmit}>

                <table className="page1">
                    <tr>
                        <td>Technical Assesment:</td>
                        <td><input type="text" width="200" name="item_35_tec_assesment" value={formData.item_35_tec_assesment} onChange={handleChange} /></td>
                        <td>(35 TecAssesment)</td>
                    </tr>
                </table>
                
                <div className="table-title">Construction Element</div>
                
                <table className="page1">
                    <tr>
                        <td>Construction Element UniqueID:</td>
                        <td><input type="text" name="item_09_construction_element_uid" value={formData.item_09_construction_element_uid} onChange={handleChange} /></td>
                        <td>(9 UniqueID)</td>
                    </tr>
                    <tr>
                        <td>Construction Element:</td>
                        <td><input type="text" name="item_02_type" value={formData.item_02_type} onChange={handleChange} /></td>
                        <td>(2 Type)</td>
                    </tr>
                    <tr>
                        <td>Density [kg/m3]:</td>
                        <td><input type="text" name="item_15_structural_asset" value={formData.item_15_structural_asset} onChange={handleChange} /></td>
                        <td>(15 Structural/Asset)</td>
                    </tr>
                    <tr>
                        <td>Material:</td>
                        <td><input type="text" name="item_10_name" value={formData.item_10_name} onChange={handleChange} /></td>
                        <td>(10 Name)</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><input type="text" name="item_11_material_name" value={formData.item_11_material_name} onChange={handleChange} /></td>
                        <td>(11 Material/Name)</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><input type="text" name="item_12_material_category" value={formData.item_12_material_category} onChange={handleChange} /></td>
                        <td>(12 Material/Category)</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><input type="text" name="item_13_material_class" value={formData.item_13_material_class} onChange={handleChange} /></td>
                        <td>(13 Material/Class)</td>
                    </tr>
                    <tr>
                        <td>Thickness [mm]</td>
                        <td><input type="text" name="item_07_length" value={formData.item_07_length} onChange={handleChange} /></td>
                        <td>(7 LengthMm)</td>
                    </tr>

                    <tr>
                        <td>Required fire resistance period [min]</td>
                        <td><input type="text" name="item_14_fire_rating" value={formData.item_14_fire_rating} onChange={handleChange} /></td>
                        <td>(14 FireRating)</td>
                    </tr>


                </table>

                <div className="table-title">Provision for Void (PfV)</div>

                <table className="page1">
                    <tr>
                        <td>UniqueID:</td>
                        <td><input type="text" name="item_01_pfv_uniqueID" value={formData.item_01_pfv_uniqueID} onChange={handleChange} /></td>
                        <td>(1 UniqueID)</td>
                    </tr>
                    <tr>
                        <td>Diameter [mm]:</td>
                        <td><input type="text" name="item_04_diameter" value={formData.item_04_diameter} onChange={handleChange} /></td>
                        <td>(4 DiameterMm)</td>
                    </tr>
                    <tr>
                        <td>Height [mm]:</td>
                        <td><input type="text" name="item_05_height" value={formData.item_05_height} onChange={handleChange} /></td>
                        <td>(5 HeightMm)</td>
                    </tr>
                    <tr>
                        <td>Width [mm]:</td>
                        <td><input type="text" name="item_06_width" value={formData.item_06_width} onChange={handleChange} /></td>
                        <td>(6 WidthMm)</td>
                    </tr>
                    <tr>
                        <td>Lenght [mm]:</td>
                        <td>{formData.item_07_length}</td>
                        <td>(7 LenghtMm)</td>
                    </tr>
                    <tr>
                        <td>Direction X, Y, Z:</td>
                        <td>
                            <input type="text" name="item_direction_x" value={formData.item_direction_x} onChange={handleChange} />
                            <input type="text" name="item_direction_y" value={formData.item_direction_y} onChange={handleChange} />
                            <input type="text" name="item_direction_z" value={formData.item_direction_z} onChange={handleChange} />

                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Location X, Y, Z:</td>
                        <td>
                            <input type="text" name="item_location_x" value={formData.item_location_x} onChange={handleChange} />
                            <input type="text" name="item_location_y" value={formData.item_location_y} onChange={handleChange} />
                            <input type="text" name="item_location_z" value={formData.item_location_z} onChange={handleChange} />
                        </td>
                        <td></td>
                    </tr>
                </table>

                <div className="table-title">MEP Element</div>
                <table className="page1">
                    <tr>
                        <td>UniqueID:</td>
                        <td><input type="text" name="item_17_MEP_element_uniqueID" value={formData.item_17_MEP_element_uniqueID} onChange={handleChange} /></td>
                        <td>(17 UniqueID)</td>
                    </tr>
                    <tr>
                        <td>Diameter [mm]:</td>
                        <td><input type="text" name="item_24_diameter" value={formData.item_24_diameter} onChange={handleChange} /></td>
                        <td>(24 DiameterMm)</td>
                    </tr>
                    <tr>
                        <td>Outer Diameter [mm]:</td>
                        <td><input type="text" name="item_24_outer_diameter" value={formData.item_24_outer_diameter} onChange={handleChange} /></td>
                        <td>(24 OuterDiameterMm)</td>
                    </tr>
                    <tr>
                        <td>Height [mm]:</td>
                        <td><input type="text" name="item_mep_elements_size_height" value={formData.item_mep_elements_size_height} onChange={handleChange} /></td>
                        <td>(22 HeightMm)</td>
                    </tr>
                    <tr>
                        <td>Width [mm]:</td>
                        <td><input type="text" name="item_23_width" value={formData.item_23_width} onChange={handleChange} /></td>
                        <td>(23 WidthMm)</td>
                    </tr>
                    <tr>
                        <td>Wall Thickness [mm]:</td>
                        <td><input type="text" name="item_25_wall_thickness" value={formData.item_25_wall_thickness} onChange={handleChange} /></td>
                        <td>(25 WallThicknessMm)</td>
                    </tr>
                    <tr>
                        <td>Penetration angle [deg]:</td>
                        <td><input type="text" name="item_26_penetration_angle" value={formData.item_26_penetration_angle} onChange={handleChange} /></td>
                        <td>(26 Penetration angle)</td>
                    </tr>
                    <tr>
                        <td>Category:</td>
                        <td><input type="text" name="item_18_category" value={formData.item_18_category} onChange={handleChange} /></td>
                        <td>(18 Category)</td>
                    </tr>
                    <tr>
                        <td>Description:</td>
                        <td><input type="text" name="item_19_description" value={formData.item_19_description} onChange={handleChange} /></td>
                        <td>(19 Description)</td>
                    </tr>
                    <tr>
                        <td>MaterialCode:</td>
                        <td><input type="text" name="item_20_material_code" value={formData.item_20_material_code} onChange={handleChange} /></td>
                        <td>(20 MaterialCode)</td>
                    </tr>         
                    <tr>
                        <td>Direction X, Y, Z:</td>
                        <td>
                            <input type="text" name="item_mep_direction_x" value={formData.item_mep_direction_x} onChange={handleChange} />
                            <input type="text" name="item_mep_direction_y" value={formData.item_mep_direction_y} onChange={handleChange} />
                            <input type="text" name="item_mep_direction_z" value={formData.item_mep_direction_z} onChange={handleChange} />
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Location X, Y, Z:</td>
                        <td>
                            <input type="text" name="item_mep_location_x" value={formData.item_mep_location_x} onChange={handleChange} />
                            <input type="text" name="item_mep_location_y" value={formData.item_mep_location_y} onChange={handleChange} />
                            <input type="text" name="item_mep_location_z" value={formData.item_mep_location_z} onChange={handleChange} />
                        </td>
                        <td></td>
                    </tr>                
                </table>

                <div className="table-title">Insulation</div>
                <table className="page1">
                    <tr>
                        <td>UniqueID:</td>
                        <td><input type="text" name="item_28_insulation_uid" value={formData.item_28_insulation_uid} onChange={handleChange} /></td>
                        <td>(28 UniqueID)</td>
                    </tr>
                    <tr>
                        <td>Description:</td>
                        <td><input type="text" name="item_30_description" value={formData.item_30_description} onChange={handleChange} /></td>
                        <td>(30 Description)</td>
                    </tr>
                    <tr>
                        <td>NationalCode:</td>
                        <td><input type="text" name="item_31_national_code" value={formData.item_31_national_code} onChange={handleChange} /></td>
                        <td>(31 NationalCode)</td>
                    </tr>
                    <tr>
                        <td>MaterialCode:</td>
                        <td><input type="text" name="item_32_material_code" value={formData.item_32_material_code} onChange={handleChange} /></td>
                        <td>(32 MaterialCode)</td>
                    </tr>
                    <tr>
                        <td>CoatingMaterialCode:</td>
                        <td><input type="text" name="item_33_coating_material_code" value={formData.item_33_coating_material_code} onChange={handleChange} /></td>
                        <td>(33 CoatingMaterialCode)</td>
                    </tr>
                    <tr>
                        <td>Thickness:</td>
                        <td><input type="text" name="item_34_given_insulation_thickness" value={formData.item_34_given_insulation_thickness} onChange={handleChange} /></td>
                        <td>(34 ThicknessMm)</td>
                    </tr>
                </table>

                <div className="table-title">JSON-Data</div>
                <table className="page1">
                    <tr>
                        <td></td>
                        <td><textarea style={{width: "500px", height: "200px"}} id="json_data" name="json_data" value={formData.json_data} onChange={handleChange} /></td>
                        <td><button className="continue-btn" onClick={handleJsonImport} style={{textDecoration: "underline", color: "grey"}}>{"evaluate"}</button></td>
                    </tr>                       
                </table>

                <Footer></Footer>

                
                <div className="navbar">
                <button className="continue-btn" type="submit" style={{textDecoration: "none", color: "black"}}>{"Apply new Import Data"}</button>
                <button className="continue-btn" onClick={showJSON} style={{textDecoration: "none", color: "black"}}>{"Copy into Clipboard"}</button>
                <button className="continue-btn" onClick={goToIntro()} style={{textDecoration: "none", color: "black"}}>{"Cancel"}</button>
                </div>
            </form>
          </div>
        </span>
    );
}

export default WizardStepEditStart;