import { useReducer } from 'react';

import InitDataContext from './init-data-context.js';

const defaultDataState = {
  item_01_pfv_uniqueID: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
  item_02_type: "Horizontal",
  item_04_diameter: "140",
  item_05_height: "",
  item_06_width: "",
  item_07_length: "150",
  item_09_construction_element_uid: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
  item_10_name: "IV-100-Leca",
  item_11_material_name: "N_LECA blockwork",
  item_12_material_category: "",
  item_13_material_class: "",
  item_14_fire_rating: "90",
  item_15_structural_asset: "2407",
  item_17_MEP_element_uniqueID: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
  item_18_category: "Abwasser",
  item_19_description: "Geberit Silent-PP",
  item_20_material_code: "",
  item_mep_elements_size_height: "",
  item_23_width: "",
  item_24_diameter: "110",
  item_24_outer_diameter: "110",
  item_25_wall_thickness: "3.6",
  item_26_penetration_angle: "90",
  item_28_insulation_uid: "",
  item_30_description: "",
  item_31_national_code: "",
  item_32_material_code: "",
  item_33_coating_material_code: "",
  item_34_given_insulation_thickness: "",
  item_35_tec_assesment: "", 
  item_direction_x: 0,
  item_direction_y: 0,
  item_direction_z: 0,
  item_location_x: -8103.6,
  item_location_y: 33333.0,
  item_location_z: 5975.0,
  item_mep_direction_x: 0,
  item_mep_direction_y: 0,
  item_mep_direction_z: 0,
  item_mep_location_x: -8103.6,
  item_mep_location_y: 33333.0,
  item_mep_location_z: 6000.0

};

const dataReducer = (state, action) => {
  const updatedValue = action.value

  if (action.type === 'SET_item_01_pfv_uniqueID'){ return { ...state, item_01_pfv_uniqueID: updatedValue } }
  if (action.type === 'SET_item_02_type'){ return { ...state, item_02_type: updatedValue } }
  if (action.type === 'SET_item_04_diameter'){ return { ...state, item_04_diameter: updatedValue } }
  if (action.type === 'SET_item_05_height'){ return { ...state, item_05_height: updatedValue } }
  if (action.type === 'SET_item_06_width'){ return { ...state, item_06_width: updatedValue } }
  if (action.type === 'SET_item_07_length'){ return { ...state, item_07_length: updatedValue } }
  if (action.type === 'SET_item_07_length_mm'){ return { ...state, item_07_length_mm: updatedValue } }
  if (action.type === 'SET_item_09_construction_element_uid'){ return { ...state, item_09_construction_element_uid: updatedValue } }
  if (action.type === 'SET_item_10_name'){ return { ...state, item_10_name: updatedValue } }
  if (action.type === 'SET_item_11_material_name'){ return { ...state, item_11_material_name: updatedValue } }
  if (action.type === 'SET_item_12_material_category'){ return { ...state, item_12_material_category: updatedValue } }
  if (action.type === 'SET_item_13_material_class'){ return { ...state, item_13_material_class: updatedValue } }
  if (action.type === 'SET_item_14_fire_rating'){ return { ...state, item_14_fire_rating: updatedValue } }
  if (action.type === 'SET_item_15_structural_asset'){ return { ...state, item_15_structural_asset: updatedValue } }
  if (action.type === 'SET_item_17_MEP_element_uniqueID'){ return { ...state, item_17_MEP_element_uniqueID: updatedValue } }
  if (action.type === 'SET_item_18_category'){ return { ...state, item_18_category: updatedValue } }
  if (action.type === 'SET_item_19_description'){ return { ...state, item_19_description: updatedValue } }
  if (action.type === 'SET_item_20_material_code'){ return { ...state, item_20_material_code: updatedValue } }
  if (action.type === 'SET_item_mep_elements_size_height'){ return { ...state, item_mep_elements_size_height: updatedValue } }
  if (action.type === 'SET_item_23_width'){ return { ...state, item_23_width: updatedValue } }
  if (action.type === 'SET_item_24_diameter'){ return { ...state, item_24_diameter: updatedValue } }
  if (action.type === 'SET_item_24_outer_diameter'){ return { ...state, item_24_outer_diameter: updatedValue } }
  if (action.type === 'SET_item_25_wall_thickness'){ return { ...state, item_25_wall_thickness: updatedValue } }
  if (action.type === 'SET_item_26_penetration_angle'){ return { ...state, item_26_penetration_angle: updatedValue } }
  if (action.type === 'SET_item_28_insulation_uid'){ return { ...state, item_28_insulation_uid: updatedValue } }
  if (action.type === 'SET_item_30_description'){ return { ...state, item_30_description: updatedValue } }
  if (action.type === 'SET_item_31_national_code'){ return { ...state, item_31_national_code: updatedValue } }
  if (action.type === 'SET_item_32_material_code'){ return { ...state, item_32_material_code: updatedValue } }
  if (action.type === 'SET_item_33_coating_material_code'){ return { ...state, item_33_coating_material_code: updatedValue } }
  if (action.type === 'SET_item_34_given_insulation_thickness'){ return { ...state, item_34_given_insulation_thickness: updatedValue } }
  if (action.type === 'SET_item_35_tec_assesment'){ return { ...state, item_35_tec_assesment: updatedValue } }
  if (action.type === 'SET_item_direction_x'){ return { ...state, item_direction_x: updatedValue } }
  if (action.type === 'SET_item_direction_y'){ return { ...state, item_direction_y: updatedValue } }
  if (action.type === 'SET_item_direction_z'){ return { ...state, item_direction_z: updatedValue } }
  if (action.type === 'SET_item_location_x'){ return { ...state, item_location_x: updatedValue } }
  if (action.type === 'SET_item_location_y'){ return { ...state, item_location_y: updatedValue } }
  if (action.type === 'SET_item_location_z'){ return { ...state, item_location_z: updatedValue } }
  if (action.type === 'SET_item_mep_direction_x'){ return { ...state, item_mep_direction_x: updatedValue } }
  if (action.type === 'SET_item_mep_direction_y'){ return { ...state, item_mep_direction_y: updatedValue } }
  if (action.type === 'SET_item_mep_direction_z'){ return { ...state, item_mep_direction_z: updatedValue } }
  if (action.type === 'SET_item_mep_location_x'){ return { ...state, item_mep_location_x: updatedValue } }
  if (action.type === 'SET_item_mep_location_y'){ return { ...state, item_mep_location_y: updatedValue } }
  if (action.type === 'SET_item_mep_location_z'){ return { ...state, item_mep_location_z: updatedValue } }

  return state;
};

const InitDataProvider = props => {
    const [dataState, dispatchDataAction] = useReducer(dataReducer, defaultDataState);
    
    const set_data_value = (name, value) => {
      if (name === 'item_35_tec_assesment') { return dispatchDataAction({type: 'SET_item_35_tec_assesment', value: value});
      } else if (name === 'item_01_pfv_uniqueID') { dispatchDataAction({type: 'SET_item_01_pfv_uniqueID', value: value});
      } else if (name === 'item_02_type') { dispatchDataAction({type: 'SET_item_02_type', value: value});
      } else if (name === 'item_04_diameter') { dispatchDataAction({type: 'SET_item_04_diameter', value: value});
      } else if (name === 'item_05_height') { dispatchDataAction({type: 'SET_item_05_height', value: value});
      } else if (name === 'item_06_width') { dispatchDataAction({type: 'SET_item_06_width', value: value});
      } else if (name === 'item_07_length') { dispatchDataAction({type: 'SET_item_07_length', value: value});
      } else if (name === 'item_09_construction_element_uid') { dispatchDataAction({type: 'SET_item_09_construction_element_uid', value: value});
      } else if (name === 'item_10_name') { dispatchDataAction({type: 'SET_item_10_name', value: value});
      } else if (name === 'item_11_material_name') { dispatchDataAction({type: 'SET_item_11_material_name', value: value});
      } else if (name === 'item_12_material_category') { dispatchDataAction({type: 'SET_item_12_material_category', value: value});
      } else if (name === 'item_13_material_class') { dispatchDataAction({type: 'SET_item_13_material_class', value: value});
      } else if (name === 'item_14_fire_rating') { dispatchDataAction({type: 'SET_item_14_fire_rating', value: value});
      } else if (name === 'item_15_structural_asset') { dispatchDataAction({type: 'SET_item_15_structural_asset', value: value});
      } else if (name === 'item_17_MEP_element_uniqueID') { dispatchDataAction({type: 'SET_item_17_MEP_element_uniqueID', value: value});
      } else if (name === 'item_18_category') { dispatchDataAction({type: 'SET_item_18_category', value: value});
      } else if (name === 'item_19_description') { dispatchDataAction({type: 'SET_item_19_description', value: value});
      } else if (name === 'item_20_material_code') { dispatchDataAction({type: 'SET_item_20_material_code', value: value});
      } else if (name === 'item_24_diameter') { dispatchDataAction({type: 'SET_item_24_diameter', value: value});
      } else if (name === 'item_24_outer_diameter') { dispatchDataAction({type: 'SET_item_24_outer_diameter', value: value});
      } else if (name === 'item_mep_elements_size_height') { dispatchDataAction({type: 'SET_item_mep_elements_size_height', value: value});
      } else if (name === 'item_23_width') { dispatchDataAction({type: 'SET_item_23_width', value: value});
      } else if (name === 'item_25_wall_thickness') { dispatchDataAction({type: 'SET_item_25_wall_thickness', value: value});
      } else if (name === 'item_26_penetration_angle') { dispatchDataAction({type: 'SET_item_26_penetration_angle', value: value});
      } else if (name === 'item_28_insulation_uid') { dispatchDataAction({type: 'SET_item_28_insulation_uid', value: value});
      } else if (name === 'item_30_description') { dispatchDataAction({type: 'SET_item_30_description', value: value}); 
      } else if (name === 'item_31_national_code') { dispatchDataAction({type: 'SET_item_31_national_code', value: value});
      } else if (name === 'item_32_material_code') { dispatchDataAction({type: 'SET_item_32_material_code', value: value});
      } else if (name === 'item_33_coating_material_code') { dispatchDataAction({type: 'SET_item_33_coating_material_code', value: value});
      } else if (name === 'item_34_given_insulation_thickness') { dispatchDataAction({type: 'SET_item_34_given_insulation_thickness', value: value});

      } else if (name === 'item_direction_x') { dispatchDataAction({type: 'SET_item_direction_x', value: value});
      } else if (name === 'item_direction_y') { dispatchDataAction({type: 'SET_item_direction_y', value: value});
      } else if (name === 'item_direction_z') { dispatchDataAction({type: 'SET_item_direction_z', value: value});

      } else if (name === 'item_location_x') { dispatchDataAction({type: 'SET_item_location_x', value: value});
      } else if (name === 'item_location_y') { dispatchDataAction({type: 'SET_item_location_y', value: value});
      } else if (name === 'item_location_z') { dispatchDataAction({type: 'SET_item_location_z', value: value});

      } else if (name === 'item_mep_direction_x') { dispatchDataAction({type: 'SET_item_mep_direction_x', value: value});
      } else if (name === 'item_mep_direction_y') { dispatchDataAction({type: 'SET_item_mep_direction_y', value: value});
      } else if (name === 'item_mep_direction_z') { dispatchDataAction({type: 'SET_item_mep_direction_z', value: value});

      } else if (name === 'item_mep_location_x') { dispatchDataAction({type: 'SET_item_mep_location_x', value: value});
      } else if (name === 'item_mep_location_y') { dispatchDataAction({type: 'SET_item_mep_location_y', value: value});
      } else if (name === 'item_mep_location_z') { dispatchDataAction({type: 'SET_item_mep_location_z', value: value});
      } else if (name === 'item_pipe_end_configuration') {
        dispatchDataAction({type: 'SET_item_pipe_end_configuration', value: value});
      } else if (name === 'max_dn_single_cable') {
        dispatchDataAction({type: 'SET_max_dn_single_cable', value: value});
      } else if (name === 'max_dn_cable_bundle') {
        dispatchDataAction({type: 'SET_max_dn_cable_bundle', value: value});
      } else if (name === 'max_dn_electro_inst_pipes') {
        dispatchDataAction({type: 'SET_max_dn_electro_inst_pipes', value: value});
      }
    };

    const dataContext = {
      item_01_pfv_uniqueID: dataState.item_01_pfv_uniqueID,
      item_02_type: dataState.item_02_type,
      item_04_diameter: dataState.item_04_diameter,
      item_05_height: dataState.item_05_height,
      item_06_width: dataState.item_06_width,
      item_07_length: dataState.item_07_length,
      item_07_length_mm: dataState.item_07_length_mm,
      item_09_construction_element_uid: dataState.item_09_construction_element_uid,
      item_10_name: dataState.item_10_name,
      item_11_material_name: dataState.item_11_material_name,
      item_12_material_category: dataState.item_12_material_category,
      item_13_material_class: dataState.item_13_material_class,
      item_14_fire_rating: dataState.item_14_fire_rating,
      item_15_structural_asset: dataState.item_15_structural_asset,
      item_17_MEP_element_uniqueID: dataState.item_17_MEP_element_uniqueID,
      item_18_category: dataState.item_18_category,
      item_19_description: dataState.item_19_description,
      item_20_material_code: dataState.item_20_material_code,
      item_mep_elements_size_height: dataState.item_mep_elements_size_height,
      item_23_width: dataState.item_23_width,
      item_24_diameter: dataState.item_24_diameter,
      item_24_outer_diameter: dataState.item_24_outer_diameter,
      item_25_wall_thickness: dataState.item_25_wall_thickness,
      item_26_penetration_angle: dataState.item_26_penetration_angle,
      item_pipe_end_configuration: dataState.item_pipe_end_configuration,
      max_dn_single_cable: dataState.max_dn_single_cable,
      max_dn_cable_bundle: dataState.max_dn_cable_bundle,
      max_dn_electro_inst_pipes: dataState.max_dn_electro_inst_pipes,
      item_28_insulation_uid: dataState.item_28_insulation_uid,
      item_30_description: dataState.item_30_description,
      item_31_national_code: dataState.item_31_national_code,
      item_32_material_code: dataState.item_32_material_code,
      item_33_coating_material_code: dataState.item_33_coating_material_code,
      item_34_given_insulation_thickness: dataState.item_34_given_insulation_thickness,
      
      item_direction_x: dataState.item_direction_x,
      item_direction_y: dataState.item_direction_y,
      item_direction_z: dataState.item_direction_z,

      item_location_x: dataState.item_location_x,
      item_location_y: dataState.item_location_y,
      item_location_z: dataState.item_location_z,

      item_mep_direction_x: dataState.item_mep_direction_x,
      item_mep_direction_y: dataState.item_mep_direction_y,
      item_mep_direction_z: dataState.item_mep_direction_z,

      item_mep_location_x: dataState.item_mep_location_x,
      item_mep_location_y: dataState.item_mep_location_y,
      item_mep_location_z: dataState.item_mep_location_z,

      item_35_tec_assesment: dataState.item_35_tec_assesment,
      items: dataState,
      set_data_value: set_data_value,
    };

    return <InitDataContext.Provider value={dataContext}>
        {props.children}
    </InitDataContext.Provider>
};

export default InitDataProvider;